<template>
  <div class="exchange">
    <!-- <search-tow></search-tow> -->
    <points-card />
    <el-row
      v-if="isShowBox"
      :gutter="15"
    >
      <el-col :md="12">
        <div @click="toDetailsClick">
          <ex-change-list @clickToExchange="clickToExchange" />
        </div>
      </el-col>
      <el-col :md="12">
        <ex-change-list />
      </el-col>
      <el-col :md="12">
        <ex-change-list />
      </el-col>
      <el-col :md="12">
        <ex-change-list />
      </el-col>
    </el-row>
    <blank
      v-else
      :blank-three="true"
    />
    <guide-four
      :is-dialog-show="isShowFour"
      :gift="true"
      :confirm-click="confirmClick"
      :no-click="noClick"
    />
    <guide-two
      :is-dialog-show="isShowTwo"
      :for-successful="true"
      :w="'40rem'"
      :no-show-click="noShowTwoClick"
    />
  </div>
</template>

<script>
// import SearchTow from '@/components/SearchTow'
import PointsCard from '@/components/PointsCard'
import ExChangeList from '@/components/ExchangeList'
import GuideFour from '@/components/PopUp/Guide_4'
import GuideTwo from '@/components/PopUp/Guide_2'
import Blank from '@/components/Blank'
export default {
  components: {
    PointsCard,
    ExChangeList,
    GuideFour,
    GuideTwo,
    Blank
  },
  data () {
    return {
      isShowFour: false,
      isShowTwo: false,
      isShowBox: false
    }
  },
  methods: {
    clickToExchange () {
      this.isShowFour = true
    },
    toDetailsClick () {
      this.$router.push({ name: 'toExchangeDetails' })
    },
    confirmClick () {
      this.isShowFour = false
      this.isShowTwo = true
    },
    noClick () {
      this.isShowFour = false
    },
    noShowTwoClick () {
      this.isShowTwo = false
      this.$router.push({ name: 'meVouchers' })
    }
  }
}
</script>

<style scoped lang="scss">
.exchange {
}
</style>
